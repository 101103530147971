module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-e6336e8875/0/cache/gatsby-remark-images-npm-6.24.0-5c967e5903-9378123c0a.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-32c8aa9537/0/cache/gatsby-plugin-manifest-npm-4.24.0-3629f3f328-5040a3844e.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"GatsbyJS","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/sun-behind-rain-cloud_1f326-fe0f.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"afb6a4ef515ef4323245dc9b1ea31614"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-640d5e8d81/0/cache/gatsby-plugin-google-gtag-npm-5.3.0-a39267fea8-d8b01aa1f4.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-98L8S9P74G"],"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-827b687983/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
